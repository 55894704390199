//adaptive placeholders
$ap-default: (
	height: 40px,
	margin: 15px,
	border: 1px,
	border-radius: 0,
	font-size: 17px,
	font-weight:normal,
	border-color: white,
	label-color: white,
	active-color: white,
	valid-color: white,
	placeholder-background-color: black,
	textarea: false
);

input.adaptive-label {
	//adaptive placeholder
	@include adaptive-placeholder();
	width:100%;
}
