$chart-animation-duration:	1s;

.chart {
	&__visual {
		font-size: 18px;
		padding-top: 4em;
		padding-bottom: 1em;

		&__content {
			position: relative;
			user-select: none;
			opacity: 0;
			transform: translateZ(0);
			transition: min-height .5s ease-out, max-height .5s ease-out;

			&.animate-out {
				pointer-events: none;
				animation: chart-animation-duration-out $chart-animation-duration 1 forwards;
			}

			&.animate-quick {
				animation-duration: $chart-animation-duration / 2;
			}

			&.drawn {
				opacity: 1;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: -$chart-bottom-border-width / 2;
				left: 0;
				right: 0;
				border: $chart-bottom-border-width / 2 solid $chart-bottom-border-color;
				border-radius: 2px;
			}

			svg {
				vertical-align: bottom;
			}
		}

		&__point {
			position: absolute;
			z-index: 2;
			transform: translate3d(-50%, -50%, 0);
			width: 2em;
			height: 2em;
			font-weight: bold;
			color: $chart-point-text-color;
			background: $chart-point-background-color;
			text-align: center;
			line-height: 2em;
			border-radius: 50%;
			box-shadow: 0 0 0 .1em transparent, 0 0 0 .2em transparent;
			transition: box-shadow .15s ease-out;
			backface-visibility: hidden;

			.animate-in & {
				animation: chart-point $chart-animation-duration 1 forwards;
			}
			.animate-in.animate-quick & {
				animation-duration: $chart-animation-duration / 2;
				animation-name: chart-point-quick;
			}

			.animate-out & {
				animation: chart-point-out $chart-animation-duration / 2 1 forwards;
			}
			.animate-out.animate-quick & {
				animation-duration: $chart-animation-duration / 2;
				animation-name: chart-point-out;
			}

			&:before {
				content: attr(data-label);
				position: absolute;
				bottom: 0;
				left: 50%;
				padding-bottom: 2.5rem;
				color: $chart-label-text-color;
				opacity: .6;
				line-height: normal;
				font-weight: normal;
				font-size: 17px;
				transform: translateX(-50%);
				transition: opacity .15s ease-out;

				.animate-in & {
					animation: chart-label $chart-animation-duration 1 forwards;
				}
				.animate-in.animate-quick & {
					animation-duration: $chart-animation-duration / 2;
					animation-name: chart-label-quick;
				}

				.animate-out & {
					animation: chart-label-out $chart-animation-duration / 2 1 forwards;
				}
				.animate-in.animate-quick & {
					animation-duration: $chart-animation-duration / 2;
				}
			}

			&--clickable {
				cursor: pointer;

				.no-touchevents &:hover {
					z-index: 3;
					//box-shadow: 0 0 0 .1em $chart-background-color, 0 0 0 .2em $chart-point-background-color;
					transition: background .3s ease-out;
					background: $attention-color;

					&:before {
						opacity: 1;
						text-shadow:
							-1px -1px 0 #000,
							0 -1px 0 #000,
							1px -1px 0 #000,
							1px 0 0 #000,
							1px 1px 0 #000,
							0px 1px 0 #000,
							-1px 1px 0 #000,
							0 0 5px #000;
					}
				}
			}

			&--bullet {
				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: .5em;
					height: .5em;
					border-radius: 50%;
					background-color: $chart-point-text-color;
					transform: translate(-50%, -50%);
					backface-visibility: hidden;
				}
			}
		}
	}
}

.ct-area {
	transform-origin: bottom;

	.animate-in & {
		animation: ct-area $chart-animation-duration / 2 1 forwards;
	}

	.animate-out & {
		animation: ct-area-out $chart-animation-duration 1 forwards;
	}
}

.ct-bar {
	stroke-width: $chart-bar-width;
	transform-origin: bottom;

	.animate-in & {
		animation: ct-area $chart-animation-duration / 2 1 forwards;
	}

	.animate-out & {
		animation: ct-area-out $chart-animation-duration 1 forwards;
	}
}


// Animations for entire chart to track duration (does nothing)
//--------------------------------------------------------------
@keyframes chart-animation-duration-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: .999;
	}
}


// Animations for area in line-chart
//-----------------------------------
@keyframes ct-area {
	0% {
		transform: scaleY(0);
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		opacity: 1;
	}
}
@keyframes ct-area-out {
	0%, 50% {
		transform: scaleY(1);
		opacity: 1;
	}
	100% {
		transform: scaleY(0);
		opacity: 0;
	}
}


// Animations for points
//-----------------------
@keyframes chart-point {
	0%, 50% {
		transform: translate3d(-50%, -100%, 0);
		opacity: 0;
	}
	100% {
		transform: translate3d(-50%, -50%, 0);
		opacity: 1;
	}
}
@keyframes chart-point-quick {
	0% {
		transform: translate3d(-50%, -100%, 0);
		opacity: 0;
	}
	100% {
		transform: translate3d(-50%, -50%, 0);
		opacity: 1;
	}
}
@keyframes chart-point-out {
	0% {
		transform: translate3d(-50%, -50%, 0);
		opacity: 1;
	}
	100% {
		transform: translate3d(-50%, -100%, 0);
		opacity: 0;
	}
}


// Animations for labels
//-----------------------
@keyframes chart-label {
	0%, 50% {
		transform: translate3d(-50%, -25%, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}
@keyframes chart-label-quick {
	0% {
		transform: translate3d(-50%, -25%, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}
@keyframes chart-label-out {
	0% {
		transform: translate3d(-50%, 0, 0);
	}
	100% {
		transform: translate3d(-50%, -25%, 0);
	}
}