#mediaQueryIndicator {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	content: "";
	visibility: hidden;
	opacity: 0;
	clip: rect(0, 0, 0, 0);
	display: block !important; // The element is hidden by default by JS, to avoid displaying it if there is no associated styling.

	@media (min-width: 540px) {
		width: 2px;
	}
	@media (min-width: 768px) {
		width: 3px;
	}
	@media (min-width: 1024px) {
		width: 4px;
	}
	@media (min-width: 1280px) {
		width: 5px;
	}
	@media (min-width: 1600px) {
		width: 6px;
	}
}