.footer {
	margin: $gutter-width 0;

	@media (min-width: $screen-sm-min) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-end;

		> div {
			flex: 0 1 auto;
		}
	}

	.footer__text {
		margin: 0 20px;

		p {
			font-size: 15px;
			line-height: 1.1;
			margin:0;
		}

		a {
			color: $footer-link-color;

			&:hover {
				color: $footer-link-color-hover;
			}
		}
	}

	.footer__logo {
		margin: 0 0 10px 20px;

		svg {
			width:160px;
			height:30px;
		}

		@media (min-width: $screen-sm-min) {
			margin:0;
			width: 265px;
			height: 36px;

			svg {
				width: 265px;
				height: 34px;
			}
		}

	}
}