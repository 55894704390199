.rte-content {
	margin-top: 72px;
	h1 {
		font-size: 90px;
		line-height: 0.9;
		font-weight: bold;
		margin: 15px 0;
		max-width: 720px;
	}
	h2 {
		font-family: "Calibri Light", Helvetica Neue, Helvetica, Arial;
		font-size: 32px;
		line-height: 1.2;
		font-weight: normal;
		margin: 15px 0;
		max-width: 720px;
	}
	h3 {
		font-size: 19px;
		line-height: 1.2;
		font-weight: bold;
		margin: 30px 0 0;

		+ p {
			margin-top: 3px;
		}
	}
	p {
		margin: 10px 0 20px;
		font-size: 17px;
		line-height: 1.2;
		font-weight: normal;
	}
	a {
		color:$brand-color;
		text-decoration: none;
		transition: color 0.5s ease-out;
		&:hover, &:focus {
			color: $attention-color;
		}
	}
	ul {
		margin-top: 3px;
	}
	aside {
		h2 {
			text-transform: uppercase;
			margin:5px 0;
		}
		p {
			margin-top: 5px;
		}
	}
}