$anim-duration: .3s;

.map-container {
	width: 100%;
	height: 0;
	max-height: 100%;
	overflow: hidden;
	transform: translateX(200vw);
	transition: transform $anim-duration ease-out;

	#map {
		width: 100%;
		height: 838px;
		background-color: black;
	}

	.clicked-bankruptcy {
		padding:0;
		> li {
			margin: 0;
			width: 100%;
		}
		.extended-view__arrow {
			display: none;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.results > ul {
		transform: translateX(0);
		transition: all $anim-duration ease-out;
	}

	.map-is-displayed {
		.results > ul {
			height: 0;
			transform: translateX(-200vw);
			overflow: hidden;
		}

		.map-container {
			transform: translateX(0);
			height: auto;
			overflow: visible;
		}
	}
}
