.content-container {
	@media (min-width: $screen-sm-min) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;

		.content-container--66 {
			width:670px;
			padding-right: 20px;
		}
		.content-container--33 {
			width:320px;
		}
	}
}