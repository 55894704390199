.chart__top {
	@include clearfix;
	margin-bottom: 20px;
	@media (min-width: $screen-sm-min) {
		margin-top: 56px;
		> div {
			float:left;
		}
		.chart__filters {
			width: 33.3333%;
		}
		.chart__numbers {
			@include clearfix;
			width: 66.6666%;
		}
	}
	@media (max-width:$screen-sm) {
		.chart__filters {
			table {
				width:100%;
				color:#000;
				tr {
					position:relative;
					width: 33.3333%;
					display: block;
					float: left;
					background: $brand-color;
				}
				td,th {
					display: block;
					width: 100%;
					text-align: center;
					border-left: 1px solid #000;

					&.chart__filters__label {
						text-transform: none;
						font-weight: normal;
						padding-right: 0;
						padding-top: 10px;
						position: absolute;
						top: -2px;
						z-index: 2;
					}
				}
			}
		}
	}

	.chart__filters {
		@media (min-width:$screen-sm-min) {
			margin-top: 13px;
			tr {
				height:63px;
				td,th {
					display: block;
					margin-bottom: 10px;
				}
			}
		}
		@media (min-width: $screen-md-min) {
			tr {
				td,th {
					display: table-cell;
					margin-bottom: 0;
				}
			}
		}
		.chart__filters__label {
			font-size: 17px;
			font-weight: bold;
			text-transform: uppercase;
			padding-right: 15px;
		}
	}
	.chart__numbers {
		margin-top: 50px;
		@media (min-width: $screen-md-min) {
			margin-top: 0;
		}
		> div {
			width:50%;
			float: left;
			padding: 0 15px;

			.numbers__amount {
				display: block;
			}
			.numbers__label {
				display: block;
				font-size: 14px;
				line-height: 0.9;
				font-weight: normal;
				text-transform: uppercase;
			}
			&.numbers__total {
				.numbers__amount {
					font-size: 150px;
					line-height: .8;
					font-weight: bold;
				}
			}
			&.numbers__citizens, &.numbers__employees {
				margin-top: 8px;
				.numbers__amount {
					font-size: 40px;
					line-height: 1;
					font-weight: bold;
					letter-spacing: 4px;
				}
			}

			@media (min-width: $screen-sm-min) {
				padding: 0;
				.numbers__label {
					font-size: 17px;
					line-height: 1.2;
				}
				&.numbers__total {
					text-align: center;
					.numbers__amount {
						font-size: 220px;
						line-height: .8;
					}
				}
				&.numbers__citizens, &.numbers__employees {
					margin-top: 8px;
					.numbers__amount {
						font-size: 70px;
						line-height: 1;
						letter-spacing: 4px;
					}
				}
			}
		}
	}
}