.header__navigation--sub {
	position: fixed;
	z-index: 2;
	top: 0;
	right: 50px;

	h6 {
		display:none;
		@media (min-width: $screen-sm-min) {
			display: block;

			position: absolute;
			right: 83px;
			color: white;
			opacity: 1;
			margin: 19px 0;
			text-align: right;
		}

		font-size: 12px;
		line-height: 1;
		font-weight: bold;
		text-transform: uppercase;

	}

	@media (min-width: $screen-sm-min) {
		position: absolute;
		top: 135px;
		//social icons are positioned different on frontpage and textpages
		#Frontpage & {
			width: 42px;

			h6 {
				position: relative;
				right:auto;
				text-align: center;
				color: $social-text-color;
				opacity: 0.5;
				margin: 16px 0 5px;
			}
		}
		right: 15px;
	}
	@media (min-width: $screen-lg-min) {
		//social icons are positioned different on frontpage and textpages
		#Frontpage & {
			right: -8px;
			h6 {
				margin-right: -8px;
			}
		}
	}

	nav {
		ul {
			margin:0;
			padding:0;
			li {
				float:left;
				a {
					width:32px;
					height:32px;
					display: block;
					margin: 9px 4px;
					span svg {
						width:32px;
						height:32px;
						fill:$social-icon-color-mobile;
					}
					&:hover, &:focus {
						span svg {
							fill:$attention-color;
						}
					}
				}
				@media (min-width: $screen-sm-min) {


					a {
						width: 32px;
						height: 32px;
						display: block;

						span svg {
							width: 32px;
							height: 32px;
							transition: fill 0.5s ease-out;
							fill: white;
						}
						&:hover, &:focus {
							span svg {
								fill: $attention-color;
							}
						}
					}
					#Frontpage & {
						float: none;
						a {
							width: 42px;
							height: 42px;
							display: block;
							margin-bottom: 10px;
							span svg {
								width: 42px;
								height: 42px;
								fill: $social-icon-color;
							}
							&:hover, &:focus {
								span svg {
									fill: $attention-color;
								}
							}
						}
					}
				}
			}
		}
		&.header__navigation--download {
			display: none;
			@media (min-width: $screen-sm-min) {
				#Frontpage & {
					display: block;
				}
			}
		}
	}
}