/*
    ==================
      GENERAL RESETS
    ==================
*/

// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
	box-sizing: border-box;
	height: 100%;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

body {
	height:100%;
	background: $body-bg;
	color: #fff;

	font-size: 17px;
	line-height: 1.2;
	font-family: Calibri, Helvetica Neue, Helvetica, Arial;
}

select {
	color: $brand-color;
}

img {
	max-width: 100%;
	height: auto;
}


//text
h1 {
	font-size: 90px;
	line-height: 0.9;
	font-weight: bold;
}

h2 {
	font-family: "Calibri Light",Helvetica Neue, Helvetica, Arial;
	font-size: 32px;
	line-height: 1.2;
	font-weight: normal;
}

h3 {
	font-size: 19px;
	line-height: 1.2;
	font-weight: bold;
}