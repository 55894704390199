.results {
	position: relative;
	@include clearfix();
	margin-top: 25px;

	> ul {
		@include clearfix();
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;

		@media (min-width: $screen-sm-min) {
			margin-left: -$gutter-width;
			width: calc(100% + 30px);
		}

		> li:not(.extended-view) {
			position: relative;
			visibility:hidden;
			cursor: pointer;
			float:left;
			width: 100%;
			height: 250px;
			margin-bottom: 0;
			border-bottom: 1px solid black;

			@media (min-width: $screen-sm-min) {
				width: calc(50% - 30px);
				margin-left: $gutter-width;
				margin-bottom: $gutter-width;
				border-bottom: none;
			}

			@media (min-width: $screen-lg-min) {
				width: 320px;
				//margin-left: $gutter-width;

				&:nth-child(2n + 1) {
					margin-left: $gutter-width;
				}
			}

			padding:20px;
			transition: background 0.5s ease-out;
			background-color:$box-bg-color;

			&.result--focus {
				background-color: $box-bg-focus;
			}

			&:hover {
				background-color: $attention-color;
				color: black;

				.extend__icon {
					svg {
						fill: black;
					}
				}
			}

			&.result--extended {
				background-color: $box-bg-focus;
				color: inherit;

				.extend__icon {
					svg {
						fill: inherit;
					}
				}
			}

			.bg-imagehandler {
				position: absolute;
				top:0;
				left:0;
				height: 100%;
				width:100%;
				background-position: center center;
				filter: grayscale(1);
				opacity: .2;
			}

			dl {
				position: relative;
				margin: 2px 0 8px;

				dt {
					font-weight: bold;
					font-size: 24px;
					line-height:1.2;

					display: inline-flex;
					align-items: center;
				}

				dd {
					font-family: "Calibri Light", Helvetica Neue, Helvetica, Arial;
					font-size: 17px;
					line-height: 1.2;
					text-transform: uppercase;
					margin: 0;
				}
			}

			.result__flexcontainer {
				width:100%;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.result__flexcontainer__column {
					flex: 0 1 auto;
					width: calc( 50% - 5px );
				}
			}

			.extend__icon {
				position: absolute;
				right:20px;
				bottom:20px;

				svg {
					width:21px;
					height: 12px;
					fill:white;
				}
			}

			.visible-extended {
				display: none;
			}

			.visible-municipality {
				display: none;
				font-size: 18px;
				line-height: 1.2;
				font-weight:normal;
				background-color: rgba(black , 0.4);
				margin: 10px;
				padding: 0 4px;

				.municipality--selected & {
					display: inline;
				}
			 }

			.hidden-municipality {
				display:flex;

				.municipality--selected & {
					display: none;

					+ .result__flexcontainer__column {
						width: 100%;
						margin-top: 10px;
					}
				}
			}

			&.result--extended {
				padding:30px 15px 30px 30px;

				.visible-extended {
					display: flex;
				}

				.hidden-extended {
					display:none;
				}

				dl {
					margin: 2px 0;

					&.result__employees {
						margin-top: 14px;
					}
				}

				.extend__icon {
					display: none;
				}
			}
		}

		li.result__extended-view {
			position:relative;
			height:0;
			clear:both;
			width:100%;
			display: none;

			@media (min-width: $screen-sm-min) {
				margin-left: $gutter-width;
				width: calc(100% - 30px);
			}

			> div {
				opacity:0;
				transition: opacity .5s ease-out;
			}

			&.fade-in {
				> div {
					opacity:1;
				}
			}

			.result__extended-view__arrow {
				//triangle not visible on mobiles
				@media (min-width: $screen-sm-min) {
					display: block;
					position: absolute;
					top: -20px;
					left: 50%;
					margin-left: -5px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 20px 20px 20px;
					border-color: transparent transparent $extended-box-bg-top transparent;
				}
			}

			.result__extended-view__top {
				padding: 20px 0;
				position: relative;
				background: $extended-box-bg-top;

				@media (min-width: $screen-sm-min) {
					&:before {
						background: $extended-box-bg-top;
						content: "";
						position: absolute;
						top: 0;
						right: 100%;
						width: 100%;
						height: 100%;
					}

					&:after {
						background: $extended-box-bg-top;
						content: "";
						position: absolute;
						top: 0;
						left: 100%;
						width: 100%;
						height: 100%;
					}
				}

				.result__extended-view__navigation--social {
					position: absolute;
					top: 80px;
					right: 15px;
					width: 32px;

					@media (min-width: $screen-sm-min) {
						position: absolute;
						top: 25px;
						right: 50px;
						width: 123px;
					}

					h6 {
						text-transform: uppercase;
						font-family: Calibri, Helvetica Neue, Helvetica, Arial;
						font-size: 12px;
						font-weight: normal;
						line-height:1.2;
						margin: 0 0 8px;
						text-align: center;

						@media (min-width: $screen-sm-min) {
							float: left;
							margin: 8px 5px 0;
							text-align: left;
						}
					}

					ul li {
						margin-top: 5px;

						@media (min-width: $screen-sm-min) {
							float: left;
							margin: 0 4px;
						}

						a {
							span svg {
								width: 32px;
								height: 32px;
								fill: #fff;
								transition: fill 0.5s ease-out;
							}

							&:hover {
								span svg {
									fill: $attention-color;
								}
							}
						}
					}
				}

				h2 {
					font-size: 40px;
					font-weight: bold;
					line-height: 1.2;
					margin: 0 0 10px;
					text-rendering: auto;
				}

				.content-container {
					p:first-child {
						margin-top: 0;
					}

					aside {
						h3 {
							text-transform: uppercase;
							font-weight: bold;
							font-size: 17px;
							line-height: 1.2;
							margin: 0 0 10px;
						}

						.link-list {
							list-style: none;
							padding: 0;
							margin: 0;

							li {
								min-height: 40px;
								display: flex;
								flex-flow: row nowrap;
								justify-content: space-between;
								align-items: center;
								border-bottom: 1px solid $extended-box-border-color;
								padding: 5px 0;

								&:first-child {
									border-top: 1px solid $extended-box-border-color;
								}

								a {
									flex: 1;
									display: block;
									padding-right: 50px;
									position: relative;
									width: 100%;

									.icon-after {
										position: absolute;
										right:0;
										top: calc(50% - 25px);
										height: 50px;

										svg {
											width:10px;
											height: 17px;
											margin-top: 16px;
											fill:currentColor;
										}
									}

									&:hover {
										color:$text-link-color-hover;
									}
								}
							}

						}
					}
				}

				.close {
					position: absolute;
					right: -15px;
					background: none;
					border: none;
					width: 50px;
					height:50px;
					outline: none;

					svg {
						width:21px;
						height:21px;
						fill:white;
					}

					&:hover {
						svg {
							fill: $attention-color;
						}
					}
				}

				@media (max-width: $screen-lg-min) {
					h2 {
						padding-right: 40px;
						margin: 0 20px 10px;
					}

					.content-container {
						.content-container--66 {
							margin: 0 20px 20px;
							padding-right: 40px;
							min-height:110px;
						}

						aside {
							h3 {
								margin: 0 20px 10px;
							}

							.link-list li {
								padding: 5px 20px;
							}
						}
					}
					.close {
						//top:-245px;
						right:5px !important;
					}
				}
			}

			.result__extended-view__bottom {
				position: relative;
				padding: 20px 0 27px;
				background: $extended-box-bg-bottom;

				@media (min-width: $screen-sm-min) {
					&:before {
						background: $extended-box-bg-bottom;
						content: "";
						position: absolute;
						top: 0;
						right: 100%;
						width: 100%;
						height: 100%;
					}

					&:after {
						background: $extended-box-bg-bottom;
						content: "";
						position: absolute;
						top: 0;
						left: 100%;
						width: 100%;
						height: 100%;
					}
				}

				h3 {
					font-size: 20px;
					font-weight: bold;
					line-height: 1;
					margin-bottom: 15px;

					@media (max-width: $screen-lg-min) {
						margin: 0 20px 15px;
					}
				}

				table {
					border-collapse:collapse;
					border-spacing:0;
					border-color: $extended-box-border-color;
					width: 100%;
					vertical-align: top;

					td, th {
						padding:10px 20px;
						border-style:solid;
						border-width:1px;
						overflow:hidden;
						word-break:normal;
						border-color:$extended-box-border-color;
						vertical-align: top;
						text-align: left;

						p {
							font-weight: normal;
							margin:0;

							&.table__label {
								opacity: .5;
								text-transform: uppercase;
								margin-bottom: 3px;
							}

							&.table__value {

							}
						}
					}
				}

				@media (max-width: $screen-sm) {
					table {
						border-bottom: 1px solid #222;

						tr {
							width:40%;
							float: left;
							display: block;

							&:first-child {
								width: 60%;
							}

							th, td {
								display: inline-block;
								width: 100%;
								border-bottom-width: 0;
								border-left-width:0;
							}
						}
					}
				}
			}

			a {
				color:white;
				text-decoration: none;
			}
		}
	}

	@media (min-width: $screen-sm-min) {
		position:relative;
	}

	.results-loader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .5);
		overflow: hidden;
		opacity: 0;
		height: 0;
		transition: height 0s 1s, opacity 1s;

		.circle {
			position: relative;
			width: 85px;
			height: 85px;
			margin: 80px auto 20px;
			border-radius: 50%;
			backface-visibility: hidden;
			box-shadow: 0 0 20px 5px rgba(0, 0, 0, .25), inset 0 0 20px 10px rgba(0, 0, 0, .25);

			svg {
				position: absolute;
				width: 100%;
				height: 100%;

				&.circleFill {
					z-index: 1;
					stroke-dasharray: 322;
					stroke-dashoffset: 322;
					transition: all 0s 1s;
					transform: rotate(-90deg);
				}

				.circleTrack {
					z-index: 0;
				}
			}
		}

		&.loading {
			height: 100%;
			opacity: 1;
			transition: height 0s 0s, opacity 1s;

			.circle {
				svg {
					&.circleFill {
						stroke-dashoffset: 38.5;
						transition: all 3s;
					}
				}
			}
		}
	}
}