$switch-color:			white;
$switch-color-hover:	$attention-color;
$switch-line-height:	3px;

.switch {
	display: inline-block;
	text-transform: uppercase;
	line-height: 10 * $switch-line-height;
	font-weight: bold;
	user-select: none;
	cursor: pointer;

	i {
		font-style: normal;
	}

	input {
		display: none;

		+ i {
			&:before {
				content: attr(data-text-before);
				display: inline-block;
				vertical-align: middle;
				margin-right: $switch-line-height * 4;
				transition: opacity .15s ease-out;
			}

			&:after {
				content: attr(data-text-after);
				display: inline-block;
				vertical-align: middle;
				margin-left: $switch-line-height * 4;
				opacity: .6;
				transition: opacity .15s ease-out;
			}

			i {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				font-size: $switch-line-height;
				height: 2em;
				width: 27em;
				border-radius: 1em;
				background-color: rgba(white, .6);

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 6em;
					height: 2em;
					border-radius: 1em;
					transform: translate3d(-125%, -50%, 0);
					background-color: $switch-color;
					transition: all .15s ease-out;
				}

				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 10em;
					height: 10em;
					border-radius: 50%;
					transform: translate3d(-150%, -50%, 0);
					background-color: $switch-color;
					transition: all .15s ease-out;
				}
			}
		}

		&:checked {
			+ i {
				&:before {
					opacity: .6;
				}

				&:after {
					opacity: 1;
				}

				i {
					&:before {
						transform: translate3d(25%, -50%, 0);
					}

					&:after {
						transform: translate3d(50%, -50%, 0);
					}
				}
			}
		}


	}
	&:hover {
		input + i {
			i {
				&:before, &:after {
					background-color: $switch-color-hover;
				}
			}
		}
	}
}