.results__top {
	position: relative;
	margin-top: 43px;
	@include clearfix;

	.results__top__switch {
	}

	.results__top__filter {
		float: right;

		@media (min-width: $screen-sm-min) {
			position: absolute;
			right: 0;
			top: 6px;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 0;
			background: $body-bg;
			opacity: 0;
			transition: opacity .5s, height 0s .5s;
		}

		.map-is-displayed & {
			&:after {
				height: 100%;
				opacity: 1;
				transition: opacity .5s, height 0s 0s;
			}
		}

		label {
			font-size: 17px;
			font-weight: bold;
			text-transform: uppercase;
			padding-right: 15px;
		}

		.selectboxit-text {
			max-width: 200px !important;
		}
	}
}