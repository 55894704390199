﻿/* ==============
Helpers
============== */

.hidden {
	display: none !important;
	visibility: hidden;
}

.hidden-sm {
	@media (max-width:$screen-sm) {
		display: none;
	}
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.clearfix {
	*zoom: 1;
	@include clearfix;
}

.hidden-checkbox {
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(0,0,0,0);
	z-index: -999999;
}

.line-height--lg {
	font-size: 70px;
	line-height: .9;
}

.line-height--sm {
	font-size: 18px;
	line-height: 1.2;
}