.wrapper {
	min-height: 100%;
	position: relative;
	overflow-x: hidden;

	> div {
		margin: 0 auto;

		@media (min-width: $screen-lg-min) {
			width: $body-content-width;
		}
	}
	.wrapper__header {
		width: 100%;

		@media (max-width: $screen-sm) {
			position: fixed;
			height: 50px;
			z-index: 2;
			border-bottom: 1px solid black;
		}

		.wrapper__inner {
			position: relative;
			margin: 0 auto;

			@media (min-width: $screen-lg-min) {
				width: $body-content-width;
			}
		}
	}

	.wrapper__main {
		position: relative;
		padding-bottom: 66px;
		margin: 0 15px;

		#Frontpage & {
			margin: 0 auto;
		}

		@media (max-width: $screen-sm) {
			padding-top: 50px;
		}

		@media (min-width: $screen-sm-min) {
			margin: 0 auto;
			padding-bottom: 66px;
		}
	}

	.wrapper__footer {
		height: 85px;

		@media (min-width: $screen-sm-min) {
			height: 36px;
		}

		.footer {
			position: absolute;
			bottom: 0;
			height:85px;

			@media (min-width: $screen-sm-min) {
				height: 36px;
			}
		}
	}
}