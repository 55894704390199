﻿//
// Variables
// --------------------------------------------------
$screen-sm:						767px;
$screen-sm-min:					768px;

$screen-md:						959px;
$screen-md-min:					960px;

$screen-lg-min:					1040px;

$body-bg:						#000;

$brand-color:					#4c86a1;
$attention-color:				#F1C600;
$light-grey:					#BCC9C5;
$grey:							#5B6770;
$dark-grey:						#3F3E3E;
$brown-grey:					#6E6259;

$text-color:					white;
$text-link-color:				#4c86a1;
$text-link-color-hover:			$attention-color;

$header-bg-desktop:				$brown-grey;
$header-bg-mobile:				$brown-grey;

$footer-link-color:				white;
$footer-link-color-hover:		$brand-color;

$gutter-width:					30px;
$body-content-width:			1020px;
$body-aside-width:				320px;

//button
$button-primary-hover:			$grey;

//social menu
$social-icon-color-mobile:		#000;
$social-icon-color:				$dark-grey;
$social-text-color:				#7F7F7F;

//hamburger menu
$button-width: 50px;                    // The width of the button area
$button-height: 50px;                   // The height of the button area
$bar-thickness: 2px;                    // The thickness of the button bars
$button-pad: 12px;                      // The left/right padding between button area and bars.
$button-bar-space: 6px;                // The spacing between button bars
$button-transistion-duration: 0.3s;     // The transition duration


// Chart colors and widths
$chart-background-color:		#4c86a1;
$chart-point-text-color:		#000;
$chart-point-background-color:	#fff;
$chart-bottom-border-color:		#fff;
$chart-bottom-border-width:		6px; // Must be divisible with 2
$chart-label-text-color:		#fff;
$chart-bar-width:				4px;

//result boxe
$box-bg-color:					$grey;
$box-bg-focus:					$brand-color;
$extended-box-bg-top:			$dark-grey;
$extended-box-bg-bottom:		darken($dark-grey,4%);
$extended-box-border-color:		#222222;