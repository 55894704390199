.button {
	border:none;
	box-shadow: none;
	margin: 20px 0;
	padding: 10px 15px;


	&.button--primary {
		background: $brand-color;
		color: #000;
		font-size: 18px;
		line-height: 1.2;
		font-weight: bold;
		transition: background 0.5s ease-out;

		&:hover {
			background: $button-primary-hover;
		}
	}
	&.button--block {
		display: block;
		width: 100%;
	}
	&.loading {
		position: relative;
		&:after {
			content: "";
			width: 31px;
			height:31px;
			background: transparent url(/static/dist/img/ajax-loader.gif) no-repeat;
			display: block;
			position:absolute;
			top:5px;
			right:5px;
		}
	}
}
