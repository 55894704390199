.wrapper__header {
	height: 50px;
	@media (min-width: $screen-sm-min) {
		height: 80px;
	}
	background:$header-bg-mobile;
	@media (min-width: $screen-sm-min){
		background:$header-bg-desktop;
	}
	.header__logo {
		padding-top: 10px;
		margin-left: 8px;
		svg {
			width:160px;
			height:30px;
		}
		@media (min-width: $screen-sm-min){
			margin-left: 0;
			padding-top: 7px;
			svg {
				width:275px;
				height:66px;
			}
		}
	}
}