nav {
	ul {
		padding: 0;
		list-style: none;
		li {

		}
	}
}

.mobil-menu__trigger-btn__label {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	.hamburger {
		display: block;
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: $button-width;
		height: $button-height;
		font-size: 0;
		text-indent: -9999px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		box-shadow: none;
		border-radius: none;
		border: none;
		cursor: pointer;
	}

	.hamburger:focus {
		outline: none;
	}

	.hamburger span {
		display: block;
		position: absolute;
		top: ($button-height / 2) - ($bar-thickness / 2);
		left: $button-pad;
		right: $button-pad;
		height: $bar-thickness;
		background: #000;
	}

	.hamburger span::before,
	.hamburger span::after {
		position: absolute;
		display: block;
		left: 0;
		width: 100%;
		height: $bar-thickness;
		background-color: #000;
		content: "";
		transform-origin: center center;
	}

	.hamburger span::before {
		top: -$bar-thickness - $button-bar-space;
	}

	.hamburger span::after {
		bottom: -$bar-thickness - $button-bar-space;
	}

	.hamburger {
		background-color: transparent;
	}
}

@media (min-width: $screen-sm-min){
	.mobil-menu__trigger-btn__label {
		display: none;
	}
}

.header__navigation {
	@media (max-width: $screen-sm) {
		position: fixed;
		top: 0;
		left: 100%;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: #000;
		padding-top: 65px;
		transition: left .3s ease-out;
	}
	@media (min-width: $screen-sm-min) {
		position: absolute;
		top: 0;
		right: 0;
	}
	nav.header__navigation--main {
		ul li a {
			color: black;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 20px;
			font-weight: normal;
			&:hover {
				color: $text-link-color-hover;
			}
		}

		@media (max-width: $screen-sm) {
			ul li a {
				display: block;
				color: white;
				text-align: center;
				padding: 15px 0;
			}
		}

		@media (min-width: $screen-sm-min) {
			ul li {
				float: left;
				padding: 10px 18px;

				&:last-child {
					padding-right: 0;
				}
				&.active {
					position: relative;
					a {
						color: $attention-color;
					}

					//arrow
					&:after, &:before {
						bottom: -19px;
						left: 50%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
					}

					&:after {
						border-color: rgba(0, 0, 0, 0);
						border-bottom-color: black;
						border-width: 20px;
						margin-left: -20px;
					}
					&:before {
						border-color: rgba(0, 0, 0, 0);
						border-bottom-color: #000;
						border-width: 21px;
						margin-left: -21px;
					}
				}
			}
		}
	}
}


/**
 * Hamburger to "x". Takes on a hamburger shape, bars slide
 * down to center and transform into an "x".
 */

#mobileMenuActive:checked ~ {
	.mobil-menu__trigger-btn__label {
		/* active state, i.e. menu open */
		.hamburger span {
			background: none;
		}

		.hamburger span::before {
			-webkit-animation: crossBefore .3s forwards ease-out; /* Safari 4+ */
		}

		.hamburger span::after {
			animation: crossAfter .3s forwards ease-out; /* Safari 4+ */
		}
	}
	.header__navigation {
		left:0;
		.header__navigation--sub {
			position: relative;
			top:20px;
			right:0;
			width: 100%;
			display: block;
			ul {
				text-align: center;
				li {
					float: none;
					display: inline-block;
					a {
						span svg {
							fill:#fff;
						}
					}
				}
			}
		}
	}
}


@keyframes crossBefore {
	  0%   {  }
	  50%	 { top:0;transform: rotate(0deg); background:white; }
	  100% { top: 0; transform: rotate(45deg); background:white;}
  }

@keyframes crossAfter {
	0%   {  }
	50%	 { bottom:0; transform: rotate(0deg); background:white; }
	100% { bottom:0; transform: rotate(-45deg); background:white; }
}