/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */

/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/

/* SelectBoxIt container */
.selectboxit-container {
	position: relative;
	display: inline-block;
	vertical-align: top;
	@media (max-width:$screen-sm){
		width:100%;
	}
}

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
	font-size: 18px;
	font-family: Calibri, Helvetica Neue, Helvetica, Arial;
	font-weight: bold;
	/* Prevents text selection */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
	white-space: nowrap;
}

/* Button */
.selectboxit-container .selectboxit {
	width:100% !important;
	@media (min-width: $screen-sm-min) {
		width: 225px !important; /* Width of the dropdown button */
		border-radius: 23px;
		overflow: hidden;
	}
	cursor: pointer;
	margin: 0;
	padding: 0;
	display: block;
	position: relative;

	&.selectboxit-open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		+ .selectboxit-options {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

/* Height and Vertical Alignment of Text */
.selectboxit-container span {
	@media (max-width: $screen-sm) {
		height: 64px;
		padding-top: 28px;
	}
	height: 45px; /* Height of the drop down */
	line-height: 45px; /* Vertically positions the drop down text */
	display: block;
}

.selectboxit-container .selectboxit-options a {
	height: 40px; /* Height of the drop down */
	line-height: 40px; /* Vertically positions the drop down text */
	display: block;
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
	outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	cursor: default;
}

/* Button Text */
.selectboxit-text {
	text-indent: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	float: left;
	@media (max-width: $screen-sm) {
		width: 100%;
		text-align: center;
		max-width: none !important;
		text-indent: 0;
		line-height: 1.2 !important;
		height: auto !important;
	}
}

.selectboxit .selectboxit-option-icon-container {
	display: none;
	margin-left: 5px;
}

/* Options List */
.selectboxit-container .selectboxit-options {
	box-sizing: border-box;
	min-width: 100%;  /* Minimum Width of the dropdown list box options */
	*width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	cursor: pointer;
	display: none;
	z-index: 9999999999999;
	border-radius: 0;
	border-bottom-right-radius: 23px;
	border-bottom-left-radius: 23px;
	text-align: left;
	box-shadow: none;
	width:100%;
	@media (min-width: $screen-sm-min) {
		width: 225px; /* Width of the dropdown button */
	}
}

/* Individual options */
.selectboxit-option .selectboxit-option-anchor{
	padding: 0 5px 0 25px;
}

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
	text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
	text-indent: 0; /* Horizontal Positioning of the select box option text */
	margin: 0;
	list-style-type: none;
	border-top: 1px solid #000;
}

/* The first Drop Down option */
.selectboxit-option-first {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

/* The last Drop Down option */
.selectboxit-option-last {
	border-bottom-right-radius:0;
	border-bottom-left-radius: 0;
}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
	font-weight: bold;
}

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
	cursor: default;
}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
	/* Positions the down arrow */
	width: 40px;
	position: absolute;
	right: 0;
	@media(max-width: $screen-sm){
		left: 50%;
		margin-left: -15px;
		top: 15px;
	}
}

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
	/* Horizontally centers the down arrow */
	position: absolute;
	@media(max-width: $screen-sm) {
		top: 42px;
	}
	top:50%;
	right: 0;
	left: 0;
	margin-top: -6px;
}

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
	top: 30%;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
	float: left;
}

.selectboxit-container .selectboxit-option-icon {
	margin: 0;
	padding: 0;
	vertical-align: middle;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
	width: 18px;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	height: 100%;
	background-position: center;
	float: left;
}

.selectboxit-rendering {
	display: inline-block !important;
	*display: inline !important;
	zoom: 1 !important;
	visibility: visible !important;
	position: absolute !important;
	top: -9999px !important;
	left: -9999px !important;
}

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
	background-color: inherit;
}

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
	background-position: -64px -16px;
}

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
	background-color: $brand-color;
	color:black;
	transition: background .3s ease-out;
}

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
	color: #000;
	background-color: $attention-color;
	text-decoration: none;

	@media (max-width: $screen-sm){
		.selectboxit-default-arrow {
			background: url(/static/dist/img/arrow-down--yellow--round.svg);
		}
	}
}


.selectboxit-default-arrow {
	width: 30px;
	height: 30px;
	background: url(/static/dist/img/arrow-down--blue--round.svg);
	@media (min-width: $screen-sm-min) {
		width: 21px;
		height: 12px;
		background: url(/static/dist/img/arrow-down--black.svg);
	}
}

.selectboxit-list {
	background-color: $brand-color;
}

.selectboxit-list .selectboxit-option-anchor {
	color: #FFF;
	transition: background .3s ease-out;
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
	color: #000;
	background-color: $attention-color;
}

.selectboxit-list > .selectboxit-disabled {
	display: none;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
	color: #999999;
}


//special styling on list filter select
#results__top__selectSelectBoxItContainer {
	width: auto !important;
	.selectboxit {
		width:auto !important;
		padding-right: 35px;
		border-radius:0!important;
	}
	span {
		height: auto;
		line-height: 17px;
		padding-top: 0 !important;
		left: inherit !important;
		margin-left: 0 !important;
	}
	.selectboxit-btn {
		background: none;
		margin-bottom: 8px;
		color:white;
		&.selectboxit-enabled:hover, &.selectboxit-enabled:focus {
			color:white;
		}
		.selectboxit-text {
			line-height: 1.2;
			text-indent: 0;
			font-size: 17px;
			text-transform: uppercase;
		}
		.selectboxit-default-arrow {
			top: 3px;
			margin-top: 0;
			margin-left: 20px;
			background:url(/static/dist/img/arrow-down--white.svg);

			@media (max-width: $screen-sm) {
				width: 21px !important;
				height: 12px !important;
				top: -12px !important;
			}
		}
	}
	.selectboxit-options {
		width:280px;
		right:0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		top:5px;
		@media (max-width: $screen-sm) {
			top:25px !important;
		}
	}
}